<script setup lang="ts">
import type { SizeChartFragment, SizeChartTableRowValueFragment } from '#graphql-operations'

const props = defineProps<{
  sizeChart: SizeChartFragment
  sizeType: 'womens' | 'mens' | string
}>()

const { t, locale } = useI18n()

type Row = NonNullable<ElementOf<NonNullable<SizeChartFragment['table']>['rows']>>
type MeasurementKey = Exclude<keyof Row, 'size'>
type Measurement = Row[MeasurementKey]
type MeasurementUnits = keyof SizeChartTableRowValueFragment
type MeasurementRange = SizeChartTableRowValueFragment[MeasurementUnits]

const currentUnit = ref<MeasurementUnits>('cm')

const measurementDisplayNames = {
  chest: t('size_chart.chest'),
  waist: t('size_chart.waist'),
  hips: t('size_chart.hips'),
  bust: t('size_chart.bust'),
  underBust: t('size_chart.underbust'),
  // Add more mappings if necessary
}

const sizes = computed(() => props.sizeChart.table?.rows ?? [])

const measurementKeys = computed(() => {
  const keys = new Set<MeasurementKey>()
  sizes.value.forEach((item) => {
    if (item) {
      objectKeys(item).forEach((key) => {
        if (key !== 'size')
          keys.add(key)
      })
    }
  })

  // Filter out keys where all values are null or undefined
  const filteredKeys = Array.from(keys).filter((key) => {
    return sizes.value.some(item => item && item[key] !== null && item[key] !== undefined && hasMeasurementData(item[key]))
  })

  return filteredKeys
})

const cellsWidth = computed(() => {
  return {
    0: 'wfull',
    1: 'w-1/2',
    2: 'w-1/3',
    3: 'w-1/4',
    4: 'w-1/5',
    5: 'w-1/6',
  }[measurementKeys.value.length]
})

// Helper function to check if measurement has data for the current currentUnit
function hasMeasurementData(measurement: Measurement) {
  if (!measurement)
    return false
  const currentUnitData = measurement[currentUnit.value]
  if (!currentUnitData)
    return false
  return currentUnitData.from !== null && currentUnitData.to !== null
}

function formatMeasurement(measurement: MeasurementRange) {
  const { from, to } = measurement ?? {}
  if (from == null && to == null)
    return '-'
  return from === to || to == null ? `${from}` : `${from} - ${to}`
}

const hoveredRowIndex = ref<number | null>(null)
const hoveredColIndex = ref<number | null>(null)

function onCellMouseEnter(rowIndex: number, colIndex: number) {
  if (rowIndex > 0 && colIndex > 0) {
    hoveredRowIndex.value = rowIndex
    hoveredColIndex.value = colIndex
  }
}

function onCellMouseLeave() {
  hoveredColIndex.value = hoveredRowIndex.value = null
}

function cellClass(rowIndex: number, colIndex: number) {
  if (rowIndex === 0 || colIndex === 0) {
    return {}
  }

  const isHovered = rowIndex === hoveredRowIndex.value && colIndex === hoveredColIndex.value
  const isInRow = rowIndex === hoveredRowIndex.value && colIndex <= (hoveredColIndex.value ?? 0)
  const isInCol = colIndex === hoveredColIndex.value && rowIndex <= (hoveredRowIndex.value ?? 0)
  const isHighlighted = isInRow || isInCol
  return {
    'bg-slate-200': isHovered,
    'bg-muted': isHighlighted,
  }
}
</script>

<template>
  <div>
    <div class="my4 flex justify-center">
      <div dir="ltr">
        <div
          dir="ltr"
          role="tablist"
          aria-orientation="horizontal"
          tabindex="0"
          class="h10 inline-flex items-center justify-center rd-md bg-muted p1 text-muted-foreground outline-none"
        >
          <button
            v-for="unit in ['cm', 'inch'] as const" :id="`tabs-trigger-${unit}`" :key="unit"
            role="tab"
            type="button"
            :aria-selected="unit === currentUnit"
            :data-state="unit === currentUnit ? 'active' : 'inactive'"
            :tabindex="unit === currentUnit ? '0' : '-1'"
            :data-active="unit === currentUnit"
            class="inline-flex items-center justify-center ws-nowrap rd-sm px3 py1.5 text-sm fw500 ring-offset-background transition-all disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-ring"
            @mousedown.left="(event) => event.ctrlKey === false ? (currentUnit = unit) : event.preventDefault()"
            @keydown.enter.space="currentUnit = unit"
            @focus="() => { unit !== currentUnit && (currentUnit = unit) }"
          >
            {{ unit }}
          </button>
        </div>
        <div
          role="tabpanel"
          data-state="active"
          tabindex="0"
          class="mt2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-ring"
        >
          <table class="my2.5 wfull border-collapse border-collapse border-spacing-0 table-fixed select-none border border-slate-300 text-left text-sm">
            <thead>
              <tr>
                <th class="border bg-muted p2 text-center">
                  {{ t('attribute.size.label') }}
                </th>
                <th v-for="key in measurementKeys" :key="key" class="border bg-muted p2 text-center capitalize">
                  {{ measurementDisplayNames[key] || key }}
                </th>
              </tr>
            </thead>

            <tbody v-if="sizes">
              <tr v-for="(item, rowIndex) in sizes" :key="rowIndex">
                <template v-if="item">
                  <td
                    class="break-all border bg-muted px2.5 py1.25 text-center"
                    :class="cellsWidth"
                  >
                    <span class="text-primary fw600">
                      {{ item.size }}
                    </span>
                  </td>
                  <td
                    v-for="(key, colIndex) in measurementKeys" :key="key"
                    :class="[cellClass(rowIndex + 1, colIndex + 1), cellsWidth]"
                    class="break-all border-r border-r-slate-300 px2.5 py1.25 text-center"
                    @mouseenter="onCellMouseEnter(rowIndex + 1, colIndex + 1)"
                    @mouseleave="onCellMouseLeave"
                  >
                    {{ formatMeasurement(item[key]?.[currentUnit]) }}
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <ContentQuery
      v-slot="{ data }"
      path="size-guide/womens"
      find="one"
      :locale="locale"
    >
      <ContentRenderer class="max-w-full prose" :value="data" />
    </ContentQuery>
  </div>
</template>
