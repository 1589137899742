import type { AssetFragment, ProductFragment, ProductVariantFragment } from '#graphql-operations'
import type { ComputedRef, Ref } from 'vue'

export function useProductImages(
  product?: Ref<ProductFragment>,
  selectedVariant?: ComputedRef<ProductVariantFragment | undefined>,
) {
  const createImageWithAlt = (asset: AssetFragment, altText: string) => ({
    ...asset,
    alt: altText,
  })

  const productFeaturedImage = computed(() => product?.value?.featuredAsset ? createImageWithAlt(product.value.featuredAsset, product.value.name) : undefined)

  const productImages = computed(() => product?.value?.assets?.map(asset => createImageWithAlt(asset, product.value.name)) ?? [])

  const variantImages = computed(() => {
    const variants = product?.value?.variants ?? []
    return variants
      .filter(variant => variant.featuredAsset)
      .map(variant => createImageWithAlt(variant.featuredAsset!, variant.name ?? product?.value?.name ?? ''))
  })

  const selectedVariantFeaturedImage = computed(() => {
    if (selectedVariant?.value?.featuredAsset) {
      const altText = [
        product?.value?.name,
        ...(selectedVariant.value.options?.map(o => o.name) ?? []),
      ].filter(Boolean).join(' ')
      return [createImageWithAlt(selectedVariant.value.featuredAsset, altText)]
    }
    return []
  })

  const allImages = computed(() => [
    productFeaturedImage.value,
    ...productImages.value,
    ...variantImages.value,
    ...selectedVariantFeaturedImage.value,
  ].filter((image): image is NonNullable<typeof image> => Boolean(image?.id)))

  const uniqueImages = computed(() => Array.from(new Map(allImages.value.map(img => [img.id, img])).values()))

  const isLifestyleImage = (image: AssetFragment) => image.tags?.some(tag => tag.value === 'lifestyle')

  const lifestyleImages = computed(() => uniqueImages.value.filter(isLifestyleImage))
  const images = computed(() => uniqueImages.value.filter(image => !isLifestyleImage(image)))

  return { images, lifestyleImages }
}
