<script setup lang="ts">
import type { AssetFragment } from '#graphql-operations'
import { isVideo } from '~/utils/assets'

const props = defineProps<{
  asset: AssetFragment & { alt: string }
  index: number
  currentPage: number
}>()

const emit = defineEmits<{
  (e: 'zoom', value: boolean): void
}>()

const zoomedIndex = inject<Ref<number | null>>('zoomedIndex', ref(null))

const SCALE_FACTOR = 1.4
const DEFAULT_SCALE = 0.5
const ORIGIN_ASPECT_RATIO = 0.75

const isZoomed = ref(false)
const imageWrapperRef = ref<HTMLElement | null>(null)
const transformStyle = ref<string>('none')
const transformOrigin = ref('50% 50%')

function toggleZoom(event: MouseEvent) {
  isZoomed.value = !isZoomed.value
  if (isZoomed.value) {
    handleMouseMove(event)
    emit('zoom', isZoomed.value)
  }
  else {
    transformStyle.value = 'none'
    transformOrigin.value = '50% 50%'
    emit('zoom', isZoomed.value)
  }
}

function handleMouseMove(event: MouseEvent) {
  if (!isZoomed.value || !imageWrapperRef.value)
    return

  const imageWrapper = imageWrapperRef.value
  const { height, width, top } = imageWrapper.getBoundingClientRect()
  const mouseY = event.clientY

  const originY = ((mouseY - top) / height - DEFAULT_SCALE) * SCALE_FACTOR + DEFAULT_SCALE
  const scaleFactor = Math.min(1, Math.max(0, originY))
  const scale = width / (height * ORIGIN_ASPECT_RATIO)

  transformStyle.value = `scale(${scale})`
  transformOrigin.value = `50% ${scaleFactor * 100}%`
}

watch(zoomedIndex, (newIndex) => {
  if (newIndex !== props.index) {
    isZoomed.value = false
    transformStyle.value = 'none'
    transformOrigin.value = '50% 50%'
  }
})
</script>

<template>
  <div ref="imageWrapperRef">
    <div
      class="m-auto w-[75vh] transition-transform duration-150 ease-in-out"
      :style="{ transform: transformStyle, transformOrigin }"
      @click="toggleZoom"
      @mousemove="handleMouseMove"
    >
      <div
        data-testid="productAsset"
        class="relative h0 wfull of-hidden rd-1 bg-slate-100 pt-[calc(133.333%)] [&>img,&>video]:absolute [&>img,&>video]:inset-0 [&>img,&>video]:m-auto [&>img,&>video]:hfull [&>img,&>video]:max-h-full [&>img,&>video]:max-w-full [&>img,&>video]:wfull [&>img,&>video]:object-cover [&>img,&>video]:object-center-center"
        :class="isZoomed ? 'cursor-zoom-out' : 'cursor-zoom-in'"
      >
        <video
          v-if="isVideo(asset)"
          height="100%"
          width="100%"
          preload="auto"
          loading="lazy"
          :poster="asset.customFields?.videoPreview?.preview || asset.preview"
          :alt="asset.alt"
          data-testid="productVideoView"
          class="aspect-3/4 rd-0.5 align-bottom"
          autoplay
          muted
          loop
          playsinline
        >
          <source :src="asset.source" type="video/mp4">
        </video>
        <NuxtImg
          v-else
          height="100%"
          width="100%"
          decoding="async"
          loading="lazy"
          :sizes="isZoomed ? '' : '100vw 2xl:50vw'"
          :src="asset.preview"
          :alt="asset.alt"
          data-testid="productImageView"
          class="aspect-3/4 rd-0.5 align-bottom"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
