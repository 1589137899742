<script setup lang="ts">
import type { AssetFragment } from '#graphql-operations'
import { isVideo } from '~/utils/assets'

const props = defineProps<{
  assets: (AssetFragment & { alt: string })[]
}>()

const emit = defineEmits<{
  (e: 'click', index: number): void
}>()

const selectedImageIndex = ref(0)

const { assets } = toRefs(props)

const MAX_IMAGES = 12

const visibleImages = computed(() => assets.value.slice(0, MAX_IMAGES))

function getItemClass(index: number): string {
  const baseClass = 'mb1 list-none '
  const totalImages = assets.value.length

  switch (totalImages) {
    case 1:
      return `${baseClass}wfull lg:w-[calc(100%)]`
    case 2:
    case 3:
    case 4:
      return `${baseClass}wfull lg:w-[calc(50%-2px)]`
    case 5:
      if (index < 2) {
        return `${baseClass}wfull lg:w-[calc(50%-2px)]`
      }
      return `${baseClass + (index === 2 ? 'wfull' : 'w-[calc(50%-2px)]')} lg:w-[calc(33.3%-2px)]`
    case 6:
      if (index < 4) {
        return `${baseClass}wfull lg:w-[calc(50%-2px)]`
      }
      return `${baseClass}w-[calc(50%-2px)]`
    case 7:
      if (index < 3) {
        return `${baseClass}wfull lg:w-[calc(50%-2px)]`
      }
      return `${baseClass}w-[calc(50%-2px)] ${index < 4 ? '' : 'lg:w-[calc(33.3%-2px)]'}`
    case 8:
      if (index < 4) {
        return `${baseClass}wfull lg:w-[calc(50%-2px)]`
      }
      return `${baseClass}w-[calc(50%-2px)] lg:w-[calc(25%-3px)]`
    case 9:
      if (index < 3) {
        return `${baseClass}wfull lg:w-[calc(50%-2px)]`
      }
      return `${baseClass}w-[calc(50%-2px)] ${index < 6 ? '' : 'lg:w-[calc(33.3%-2px)]'}`
    case 10:
      if (index < 4) {
        return `${baseClass}wfull lg:w-[calc(50%-2px)]`
      }
      if (index < 6) {
        return `${baseClass}w-[calc(50%-2px)]`
      }
      return `${baseClass}w-[calc(50%-2px)] lg:w-[calc(25%-3px)]`
    case 11:
      if (index < 5) {
        return `${baseClass}wfull lg:w-[calc(50%-2px)]`
      }
      if (index < 8) {
        return `${baseClass}w-[calc(50%-2px)]`
      }
      return `${baseClass}w-[calc(50%-2px)] lg:w-[calc(33.3%-2px)]`
    default:
      if (index < 6) {
        return `${baseClass}wfull lg:w-[calc(50%-2px)]`
      }
      if (index < 8) {
        return `${baseClass}w-[calc(50%-2px)]`
      }
      return `${baseClass}w-[calc(50%-2px)] lg:w-[calc(25%-3px)]`
  }
}
function onImageClick(index: number) {
  selectedImageIndex.value = index
  emit('click', index)
}
</script>

<template>
  <ul
    data-testid="productImageGrid"
    class="wfull flex flex-wrap justify-between p0"
  >
    <li
      v-for="(asset, index) in visibleImages" :key="index"
      data-testid="productImageGridImageItem"
      :class="getItemClass(index)"
    >
      <RdxDialogRoot>
        <RdxDialogTrigger as-child>
          <button
            data-testid="productImageGridImageButton"
            class="relative hfull wfull cursor-zoom-in border-none bg-slate-100 p0"
            @click="onImageClick(index)"
          >
            <div
              data-testid="productAsset"
              class="relative hfull wfull of-hidden rd-1 bg-slate-100 pt-[133.333%] after:pointer-events-none after:absolute after:left-0 after:top-0 after:hfull after:wfull after:bg-white after:op0 after:content-[''] [&>img,&>video]:absolute [&>img,&>video]:inset-0 [&>img,&>video]:m-auto [&>img,&>video]:hfull [&>img,&>video]:max-h-full [&>img,&>video]:max-w-full [&>img,&>video]:wfull [&>img,&>video]:object-cover [&>img,&>video]:object-center-center"
            >
              <video
                v-if="isVideo(asset)"
                height="100%"
                width="100%"
                :preload="index < 2 ? 'auto' : 'metadata'"
                :importance="index < 2 ? 'high' : 'low'"
                :fetchpriority="index < 2 ? 'high' : 'low'"
                :loading="index < 2 ? 'eager' : 'lazy'"
                :poster="asset.customFields?.videoPreview?.preview || asset.preview"
                :alt="asset.alt"
                data-testid="productVideoView"
                class="aspect-3/4 rd-0.5 align-bottom"

                autoplay muted loop playsinline
              >
                <source :src="asset.source" type="video/mp4">
              </video>
              <NuxtImg
                v-else
                height="100%"
                width="100%"
                :decoding="index < 2 ? 'sync' : 'async'"
                :importance="index < 2 ? 'high' : 'low'"
                :fetchpriority="index < 2 ? 'high' : 'low'"
                :loading="index < 2 ? 'eager' : 'lazy'"
                sizes="33.333vw lg:50vw"
                :src="asset.preview"
                alt=""
                data-testid="productImageView"
                class="aspect-3/4 rd-1 align-bottom"
              />
              <slot v-if="assets.length === 1 || index === 1" name="badges" />
            </div>
          </button>
        </RdxDialogTrigger>

        <RdxDialogPortal>
          <RdxDialogOverlay />
          <RdxDialogContent class="fixed inset-0 z999 bg-background/60 will-change-opacity">
            <RdxDialogTitle class="sr-only">
              Image Details
            </RdxDialogTitle>
            <RdxDialogDescription class="sr-only">
              Use the left and right arrows to navigate between images
            </RdxDialogDescription>

            <ProductImageDetails :assets="visibleImages" :initial-index="selectedImageIndex" />

            <RdxDialogClose
              v-wave
              data-testid="modalCloseIcon"
              class="absolute right-0 top-0 flex cursor-pointer of-hidden border-0 p6 outline-none will-change-transform,opacity"
              aria-label="Close"
            >
              <Icon name="ph:x-bold" class="block h8 w8 shrink-0 grow-0 basis-8" />
            </RdxDialogClose>
          </RdxDialogContent>
        </RdxDialogPortal>
      </RdxDialogRoot>
    </li>
  </ul>
</template>
