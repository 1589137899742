<script setup lang="ts">
import type { ProductFragment, ProductOptionGroupFragment, ProductVariantFragment } from '#graphql-operations'
import { useToast } from '~/components/ui/toast'
import { AddToObservedError } from '~/constants/errors'
import { useRestockReminderSchema } from '~/schemas'

const props = defineProps<{
  product: ProductFragment
  selectedVariant?: ProductVariantFragment
}>()

const { t } = useI18n()

const optionGroups = computed(() => {
  return props.product?.optionGroups.reduce((acc, optionGroup) => ({ ...acc, [optionGroup.code]: optionGroup }), {} as Record<string, ProductOptionGroupFragment>)
})

const outOfStockVariants = computed(() => props.product.variants.filter(variant => variant.stockLevel !== 'IN_STOCK'))

const { isOutOfStock, clearSelectedOptions, selectedOptions, selectedVariants, availableOptions, isColorGroupWithImages, handleOptionChange } = useProductOptions(outOfStockVariants.value)

const isOpen = ref(false)
const { toast } = useToast()
const alert = ref('')

const { handleSubmit, isSubmitting, resetForm } = useForm({
  validationSchema: useRestockReminderSchema(),
})

async function subscribeToRestockReminder(email: string, productVariantID: string) {
  const { data, errors } = await useGraphqlMutation('addToObserved', { input: { email, productVariantID, watchdog: true } })
  if (data.addToObserved?.__typename === 'AddToObservedError') {
    throw new AddToObservedError(data.addToObserved.message)
  }
  if (errors.length) {
    throw new Error(errors[0].message)
  }
}

const onSubmit = handleSubmit(async (values) => {
  alert.value = ''

  try {
    const variantIds = selectedVariants.value.map(variant => variant.id)

    if (!variantIds.length) {
      throw new Error('Please select a variant.')
    }

    const subscribe = variantIds.map(variantId => subscribeToRestockReminder(values.email, variantId))
    await Promise.all(subscribe)

    toast({
      title: t('restock_reminder.success'),
      variant: 'success',
    })

    resetForm()
    isOpen.value = false
  }
  catch (error) {
    if (error instanceof Error)
      alert.value = error.message
    console.error(error)
  }
})

function onOpen(opening: boolean) {
  if (opening) {
    const optionGroupCodes = props.selectedVariant?.options.map(option => option.group.code)
    if (optionGroupCodes) {
      optionGroupCodes.forEach((optionGroupCode) => {
        const optionCode = props.selectedVariant?.options.find(option => option.group.code === optionGroupCode)?.code
        if (optionCode) {
          handleOptionChange(optionGroupCode, [optionCode])
        }
      })
    }
  }
  else {
    resetForm()
    alert.value = ''
    clearSelectedOptions()
  }
}
</script>

<template>
  <UiDialog v-model:open="isOpen" @update:open="onOpen">
    <UiDialogTrigger as-child>
      <slot name="trigger" />
    </UiDialogTrigger>
    <UiDialogContent class="md:max-w-[340px]">
      <UiDialogHeader>
        <UiDialogTitle class="sr-only">
          {{ isOutOfStock ? t('restock_reminder.title.product') : t('restock_reminder.title.option') }}
        </UiDialogTitle>
      </UiDialogHeader>

      <div class="flex flex-col items-center pt4 text-center">
        <div
          v-if="product?.featuredAsset?.preview"
          class="h-[152px] w-[114px] border border-input rd-0.5 p3.5"
        >
          <div data-testid="productImage" class="relative h0 wfull of-hidden border-input rd-0.75 pt-[133.333%]">
            <img
              height="100%"
              width="100%"
              decoding="async"
              loading="lazy"
              sizes="25vw"
              :src="product.featuredAsset.preview"
              :alt="product.name"
              data-testid="productImageView"
              class="absolute inset-0 m-auto hfull max-h-full max-w-full wfull object-cover object-center-center"
            >
          </div>
        </div>
        <span class="mb1.5 mt8 block text-4 text-primary fw600">
          {{ isOutOfStock ? t('restock_reminder.title.product') : t('restock_reminder.title.option') }}
        </span>
        <span class="text-xs text-muted-foreground fw500 leading-[1.5]">
          {{ t('restock_reminder.description') }}
        </span>
      </div>

      <RdxToggleGroupRoot
        v-for="(options, optionGroupCode) in availableOptions"
        :id="`option-group-${optionGroupCode}`"
        :key="optionGroupCode"
        type="multiple"
        :model-value="selectedOptions[optionGroupCode]"
        :data-testid="`productOptions-${optionGroupCode}`"
        @update:model-value="handleOptionChange(optionGroupCode, $event)"
      >
        <div v-if="optionGroups?.[optionGroupCode].code.includes('size')" class="text-4 fw600">
          {{ t('restock_reminder.select_size') }}
        </div>

        <div class="mt3 flex flex-wrap gap2" :data-testid="`productOptionsWrapper-${optionGroupCode}`">
          <RdxToggleGroupItem
            v-for="(option, optionCode, index) in options"
            :id="`option-group-${optionGroupCode}-option-${option.code}`"
            :key="optionCode"
            as="div"
            :value="optionCode"
            :name="option.name"
            class="group relative inline-flex cursor-pointer items-center justify-center ws-nowrap border rounded bg-slate-100 text-sm fw600 uppercase hover:bg-accent/50 n-active:bg-transparent n-active:ring-px n-active:ring-primary n-checked:bg-transparent focus:outline-none"
            :class="[
              isColorGroupWithImages(optionGroupCode) ? 'p0.75' : 'h8 px4 rd-full',
            ]"
            :data-testid="`productColorOptionsOption-${index}`"
          >
            <!--                    @click="handleOptionClick(optionGroupCode, optionCode)" -->
            <div
              :id="`option-group-${optionGroupCode}-option-${option.code}-label`"
              :data-testid="isColorGroupWithImages(optionGroupCode) ? 'productImage' : 'productOption'"
            >
              <template v-if="isColorGroupWithImages(optionGroupCode) && option.featuredAsset?.preview">
                <LazyNuxtImg
                  width="100"
                  height="100"
                  loading="lazy"
                  decoding="async"
                  sizes="16.666vw"
                  :src="option.featuredAsset.preview"
                  :alt="option.name"
                  class="h-16 w-16"
                  data-testid="productImageView"
                />
              </template>
              <template v-else>
                {{ option.name }}
              </template>
            </div>

            <span
              class="pointer-events-none absolute border-px border-transparent rounded -inset-px n-active:border-px n-checked:border-primary"
              aria-hidden="true"
            />
          </RdxToggleGroupItem>
        </div>
      </RdxToggleGroupRoot>

      <UiAlert v-if="alert" variant="destructive">
        <Icon mode="svg" name="ph:warning-bold" class="h-4 w-4" />
        <UiAlertTitle>Error</UiAlertTitle>
        <UiAlertDescription>{{ alert }}</UiAlertDescription>
      </UiAlert>

      <form @submit="onSubmit">
        <UiFormField v-slot="{ componentField }" name="email" :validate-on-model-update="false" :validate-on-blur="false">
          <UiFormItem v-auto-animate>
            <UiFormLabel>{{ t('restock_reminder.email.label') }}</UiFormLabel>
            <UiFormControl>
              <UiInput placeholder="you@example.com" type="email" v-bind="componentField" />
            </UiFormControl>
            <UiFormMessage />
          </UiFormItem>
        </UiFormField>

        <UiDialogFooter>
          <UiButton
            type="submit"
            class="mt4 h-auto w-full px-10 py-4 fw800 leading-none tracking-wide"
            :loading="isSubmitting"
          >
            {{ t('restock_reminder.notify') }}
          </UiButton>
        </UiDialogFooter>
      </form>
    </UiDialogContent>
  </UiDialog>
</template>
