<script setup lang="ts">
import type { ProductOptionFragment, ProductOptionGroupFragment, ProductVariantFragment, SearchResultFragment } from '#graphql-operations'
import { WishlistToggle } from '#components'
import { useRouteParams } from '@vueuse/router'
import { upperFirst } from 'scule'
import { calculateDiscountPercentage } from '~/utils/discount'

const router = useRouter()
const { ecommerce } = useAnalytics()
const { t } = useI18n()
const localePath = useLocalePath()
const priceFormatter = usePriceFormatter()
const id = useRouteParams<string>('id')
const drawers = useDrawers()

const chartModalOpen = ref(false)

const COLOR_OPTION_GROUP_CODE = 'color'

const { addItemToOrder } = useActiveOrder()

const { product, pending: productLoading } = await useProduct(id)

const variants = computed(() => product?.value?.variants as ProductVariantFragment[] | undefined)

const { isOutOfStock, selectedVariantOutOfStock, selectedVariant, selectedOptions, availableOptions, isOptionAvailable, isColorGroupWithImages, handleOptionChange } = useProductOptions(variants.value!)

const currentColor = computed(() => variants.value?.[0]?.sku.replace(/^.*-([^ -]+)-[^-]+$/, '$1'))

watchOnce(currentColor, (currentColor) => {
  if (currentColor)
    selectedOptions.value[COLOR_OPTION_GROUP_CODE] = currentColor
}, { immediate: true })

const optionGroups = computed(() => {
  const baseOptionGroups = product.value?.optionGroups.reduce((acc, optionGroup) => ({ ...acc, [optionGroup.code]: optionGroup }), {} as Record<string, ProductOptionGroupFragment & { options?: ProductOptionFragment[] }>)

  if (!product.value?.otherColors?.length)
    return baseOptionGroups

  const colorOptionGroup = { id: 'color', code: COLOR_OPTION_GROUP_CODE, name: t('attribute.color.label') }
  const currentColorOption = {
    group: colorOptionGroup,
    id: '0',
    name: upperFirst((currentColor.value ?? '').toLowerCase()),
    code: currentColor.value ?? '',
    featuredAsset: product.value?.featuredAsset,
    productId: product.value?.id,
  }

  const otherColorOptionGroups = {
    ...colorOptionGroup,
    options: product.value?.otherColors?.map((other, index) => ({
      group: colorOptionGroup,
      id: (index++).toString(),
      name: upperFirst((other?.color ?? '').toLowerCase()),
      code: other?.color ?? '',
      featuredAsset: other?.featuredAsset,
      productId: other?.productId,
    })),
  }

  otherColorOptionGroups.options?.unshift(currentColorOption)
  // sort the otherColorOptionGroups.options, because there is no order on every page reload
  otherColorOptionGroups.options?.sort((a, b) => a.name.localeCompare(b.name))

  return { ...baseOptionGroups, [COLOR_OPTION_GROUP_CODE]: otherColorOptionGroups }
})

const availableColorOptions = computed(() => ({
  [COLOR_OPTION_GROUP_CODE]: optionGroups.value?.[COLOR_OPTION_GROUP_CODE]?.options
    ?.filter(option => option.code)
    .reduce((acc, option) => {
      acc[option.code] = { ...option }
      return acc
    }, {} as Record<string, ProductOptionFragment & { productId?: string, featuredAsset?: ProductVariantFragment['featuredAsset'] }>),
}))

function onOptionChange(optionGroupCode: string, optionCode: string) {
  // if (!isOptionAvailable(optionGroupCode, optionCode)) {
  //   console.warn(`Option ${optionCode} for group ${optionGroupCode} is not available.`)
  //   return
  // }
  if (optionGroupCode === COLOR_OPTION_GROUP_CODE) {
    const productId = availableColorOptions.value?.[optionGroupCode]?.[optionCode]?.productId
    if (productId)
      return navigateTo(localePath(`/products/${productId}`))
    console.warn(`Product ID for color option ${optionCode} not found.`)
  }

  handleOptionChange(optionGroupCode, optionCode)
}

watch(selectedVariant, (selectedVariant) => {
  if (selectedVariant)
    ecommerce.viewItem(selectedVariant)
})

// images
const { images, lifestyleImages } = useProductImages(product, selectedVariant)

const selectedVariantImageIndex = computed(() => images.value.findIndex(image => image.id === selectedVariant.value?.featuredAsset?.id))
const currentImageIndex = ref(selectedVariantImageIndex.value)

const addingToCart = ref(false)

async function handleAddToCart(event: PointerEvent, quantity = 1) {
  if (productLoading.value || !selectedVariant.value || addingToCart.value)
    return

  addingToCart.value = true
  await addItemToOrder(selectedVariant.value, quantity)
  addingToCart.value = false
  ecommerce.addToCart(selectedVariant.value, quantity)
  drawers.value.cart.open = true
}

const badges = computed(() => [...(product?.value?.badges || [])].reverse())

const history = useProductHistory()
const historyItems = computed(() => history?.history?.value.filter(item => item.id !== id.value))

function handleClick(item: SearchResultFragment) {
  history.addItemToHistory(item)
  ecommerce.selectItem(item)
}

useHead({
  title: computed(() => t('layouts.title', [product.value?.name ?? t('pages.title.index')])),
})

watch(productLoading, (isLoading, wasLoading) => {
  if (wasLoading && !isLoading && !product.value) {
    showError({
      statusCode: 404,
      statusMessage: t('error.product_not_found'),
    })
  }
})

const price = computed(() => {
  const variant = selectedVariant.value || variants.value?.[0]
  const { price, currency } = variant ?? {}
  return price && currency ? priceFormatter(price, currency) : 0
})

const ecomail = useEcomail()

onMounted(() => {
  ecomail.trackLastViewed(id.value)
})

const { isMobile } = useDevice()

const isOnSale = computed(() => false)

const discountPercentage = computed(() => calculateDiscountPercentage(selectedVariant.value?.priceBeforeDiscount, selectedVariant.value?.price))

const reviewCount = computed(() => {
  if (!product.value?.reviewRatingDistribution?.length)
    return 0

  return product.value.reviewRatingDistribution.reduce((acc, { total }) => acc + total, 0)
})
</script>

<template>
  <template v-if="isMobile">
    <div v-if="isOutOfStock || selectedVariantOutOfStock" class="relative flex flex-col items-center bg-muted after:absolute after:left-0 after:top-0 after:z1 after:hfull after:wfull after:bg-black/40 after:content-['']">
      <div class="relative min-w-full">
        <div
          class="relative h0 wfull of-hidden rd-1 bg-muted pt-[calc(133.333%)] [&>img]:absolute [&>img]:inset-0 [&>img]:m-auto [&>img]:hfull [&>img]:max-h-full [&>img]:max-w-full [&>img]:wfull [&>img]:object-cover [&>img]:object-center-center" data-testid="productImage"
        >
          <img
            height="100%"
            width="100%"
            decoding="async"
            loading="eager"
            sizes="25vw"
            :src="isOutOfStock ? product?.featuredAsset?.preview : selectedVariant?.featuredAsset?.preview ? selectedVariant?.featuredAsset?.preview : product?.featuredAsset?.preview"
            data-testid="productImageView"
            class="aspect-3/4 rd-0.5 align-bottom"
          >
        </div>
      </div>
      <div class="absolute left-0 top-0 z2 hfull wfull flex flex-col items-center justify-center">
        <div data-justifyend="false" class="absolute left-0 top-0 wfull flex justify-between">
          <button class="z2 flex cursor-pointer items-center justify-center p4.25 text-white" data-testid="backButton">
            <Icon name="ph:caret-left-bold" class="block h3.75 w3.75 shrink-0 grow-0" data-testid="backButtonIcon" />
          </button>
        </div>
        <span class="text-2.5 text-white fw600">
          {{ t('product.unfortunately_sold_out') }}
        </span>
        <span color="White" class="text-[2rem] text-white fw600">
          {{ t('product.sold_out') }}
        </span>
      </div>
    </div>

    <div v-else class="of-hidden sm:hidden">
      <div class="relative wfull of-hidden pb-[133.333%] transition-padding duration-500 ease-in-out">
        <div class="absolute inset-0 hfull wfull">
          <button
            class="absolute top-1.25 z3 flex cursor-pointer items-center justify-center p4.25 text-slate-400 -left-1"
            data-testid="backButton_navigation"
            @click="router.back"
          >
            <Icon class="block h3.75 w3.75 shrink-0 grow-0" name="ph:caret-left-bold" />
          </button>

          <ProductSliderFullscreen :assets="images" :initial-index="currentImageIndex" />

          <div class="absolute bottom-0 right-0 top-0 z3 mx5 my5 mt4">
            <div class="absolute right-0 top-0 flex flex-col" />
            <div class="absolute bottom-0 right-0 flex flex-col">
              <div>
                <button
                  data-testid="addToCart"
                  class="relative h12.5 w12.5 inline-flex cursor-pointer items-center justify-center of-visible border-0 rd-1/2 bg-black p1.25 text-center text-white shadow-lg outline-none transition-opacity duration-100 ease-in-out will-change-opacity,transform"
                >
                  <Icon name="ic:add-bag-simple" class="block h6 w6 shrink-0 grow-0 basis-6" />
                  <div class="absolute inset-0" />
                </button>
              </div>

              <WishlistToggle
                v-slot="{ alert, isItemWishlisted, isAnimating, handleWishlistClick }"
                :product-id="product?.id" :product-variant-id="product?.variants[0]?.id"
              >
                <button
                  type="button"
                  data-testid="addToWishlist"
                  class="relative m0 mt4 h12.5 w12.5 inline-flex shrink-0 grow-0 basis-9 basis-auto cursor-pointer items-center justify-center of-visible border-0 rd-1/2 bg-white p2 text-center text-black shadow-lg outline-none will-change-opacity,transform"
                  aria-label="Přidat položku na seznam přání"
                  :aria-pressed="isItemWishlisted"
                  @click="handleWishlistClick"
                >
                  <Icon
                    :name="isItemWishlisted ? 'ic:heart-fill' : 'ic:heart'"
                    class="block h6 w6 shrink-0 grow-0 basis-6 text-primary"
                    :class="
                      {
                        'group-hover/wishlist:animate-[2s_ease-in-out_0s_infinite_normal_none_running_heartbeat]': !isItemWishlisted && !isAnimating,
                        'animate-[500ms_ease-in-out_0s_1_normal_none_running_pulseOutward]': isAnimating && isItemWishlisted,
                        'animate-[500ms_ease-in-out_0s_1_normal_none_running_pulseInward]': isAnimating && !isItemWishlisted,
                      }
                    "
                    mode="svg"
                    :data-testid="`wishListIcon-${isItemWishlisted ? 'filled' : 'empty'}`"
                  />
                  <div class="absolute inset-0" />
                </button>
              </WishlistToggle>
              <div class="absolute left-0 top-0 z1 wfull translate-z-0" />
            </div>
          </div>

          <div class="absolute bottom-0 left-0 wfull">
            <div
              data-testid="badgesContainer"
              class="absolute bottom-5 top-auto z2 wfull flex flex-col translate-z-0 items-start p0"
            >
              <div
                v-for="badge in badges" :key="badge.id"
                data-testid="badge-GENERIC"
                class="mr1.25 mtpx h5 max-w-full w-fit inline-flex items-center gap1.25 ws-nowrap rd-r-1 bg-white py0.5 pl4 pr2 text-xs last-of-type:mr0 [&>span]:block [&>span]:of-hidden [&>span]:text-ellipsis [&>span]:ws-nowrap [&>span]:leading-[1.3]"
              >
                <span>
                  {{ badge.text }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <section
    data-testid="splitView"
    class="mb-[12vh] m-inline-auto max-w-[1920px] wfull flex p-inline-5 lg:p-inline-7.5 xl:p-inline-15"
  >
    <div
      data-testid="sectionStart"
      class="wfull pr-[calc(5px+2.7vw)] hidden sm:block sm:w-1/2 lg:w-[65%]"
    >
      <div class="sticky top-0 z1 pb-15">
        <div class="w-inherit">
          <section class="relative">
            <button
              data-testid="backButton_directEntry"
              class="absolute left-0 top-0 z1 flex cursor-pointer items-center justify-center fill-slate-400 p4.25 transition-fill duration-300"
              @click="router.back"
            >
              <Icon class="block h6 w6 shrink-0 grow-0 basis-6" name="ph:caret-left-bold" />
            </button>
            <ProductImageGrid :assets="images">
              <template #badges>
                <div class="absolute left-0 top-5 flex flex-col items-start gap1">
                  <div
                    v-for="badge in badges" :key="badge.id"
                    data-testid="badge-GENERIC"
                    class="mr1.25 mtpx h5 max-w-full w-fit inline-flex items-center gap1.25 ws-nowrap rd-r-1 bg-white py0.5 pl4 pr2 text-xs fw600 last-of-type:mr0 [&>span]:block [&>span]:of-hidden [&>span]:text-ellipsis [&>span]:ws-nowrap [&>span]:leading-[1.3]"
                  >
                    {{ badge.text }}
                  </div>
                </div>
              </template>
            </ProductImageGrid>
          </section>
        </div>
      </div>
    </div>

    <div data-testid="sectionEnd" class="wfull lg:w-[35%] sm:w-1/2 sm:pl-[calc(5px+2.7vw)]">
      <div class="sticky top-0 sm:max-w-[400px]">
        <div class="w-inherit">
          <div class="pt5">
            <!-- Product details -->
            <LazyBreadcrumbs
              v-if="product?.collections?.length"
              :collections="product.collections"
              class="mb5 text-left hidden md:block"
            />

            <div v-if="isOutOfStock || selectedVariantOutOfStock" class="mb7 pb5 md:px14.5 md:pb8 md:pt2">
              <div class="flex flex-col items-center pt4 text-center">
                <div
                  v-if="product?.featuredAsset?.preview"
                  class="h-[152px] w-[114px] border border-input rd-0.5 p3.5"
                >
                  <div data-testid="productImage" class="relative h0 wfull of-hidden rd-0.75 bg-muted pt-[133.333%] [&>img]:absolute [&>img]:inset-0 [&>img]:m-auto [&>img]:hfull [&>img]:max-h-full [&>img]:max-w-full [&>img]:wfull [&>img]:object-cover [&>img]:object-center-center">
                    <NuxtImg
                      height="100%"
                      width="100%"
                      decoding="async"
                      loading="lazy"
                      sizes="25vw"
                      :src="product.featuredAsset.preview"
                      :alt="product.name"
                      data-testid="productImageView"
                      class="aspect-3/4 rd-0.5 align-bottom"
                    />
                  </div>
                </div>
                <span class="mb1.5 mt8 block text-4 text-primary fw600">
                  {{ isOutOfStock ? t('restock_reminder.title.product') : t('restock_reminder.title.option') }}
                </span>
                <span class="text-xs text-muted-foreground fw500 leading-[1.5]">
                  {{ t('restock_reminder.description') }}
                </span>
              </div>
              <RestockReminder v-if="product" :product="product" :selected-variant="selectedVariant">
                <template #trigger>
                  <UiButton
                    data-testid="restockReminderButton"
                    aria-label="Remind me when this is available"
                    class="mt-8 h-auto w-full whitespace-normal py-4 fw800 leading-none tracking-wide"
                  >
                    {{ t('restock_reminder.remind.button') }}
                  </UiButton>
                </template>
              </RestockReminder>
            </div>

            <div v-else class="flex flex-col">
              <div class="mb2 flex flex-col-reverse items-start">
                <div
                  v-if="variants?.length"
                  class="relative wfull"
                  :class="[isOnSale ? 'h11.2' : 'h6']"
                >
                  <div class="absolute hfull wfull">
                    <div data-testid="productPrice" class="flex flex-col">
                      <div v-if="selectedVariant?.priceBeforeDiscount && discountPercentage" class="relative mb0.5 flex flex-wrap items-center justify-start">
                        <div class="flex items-center gap1 first:mr1">
                          <div class="relative rd-1 bg-red-500 px1 text-xs text-white fw700 leading-4.75 uppercase">
                            -{{ discountPercentage }}%
                          </div>
                        </div>
                        <div class="flex items-center gap1 first:mr1">
                          <span data-testid="strikePrice" class="text-xs text-muted-foreground fw600 leading-4.75 line-through">
                            {{ priceFormatter(selectedVariant.priceBeforeDiscount, selectedVariant.currency) }}
                          </span>
                        </div>
                      </div>

                      <div class="flex flex-wrap items-baseline justify-start gap1">
                        <div data-testid="finalPrice" class="flex items-baseline gap1">
                          <span class="text-xl fw700 leading-6">
                            {{ price }}
                          </span>
                        </div>
                        <span data-testid="taxText" class="text-2.5 text-muted-foreground fw600 leading-4">
                          {{ t('product.including_vat') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb3.25 flex flex-col-reverse">
                  <h1 v-if="product?.name" class="my4.5 text-base fw600" v-text="product.name" />
                </div>

                <div class="flex items-center">
                  <Icon
                    v-for="n in 5"
                    :key="n"
                    class="h4 w4 text-primary"
                    :name="n <= (product?.reviewScore ?? 0) ? 'ph:star-fill' : 'ph:star-duotone'"
                  />
                  <span>
                    ({{ reviewCount }})
                  </span>
                </div>
              </div>
            </div>

            <!-- Product Options -->
            <div class="my6 mb7 text-left">
              <HRadioGroup
                v-for="(options, optionGroupCode) in { ...availableColorOptions, ...availableOptions }"
                :id="`option-group-${optionGroupCode}`"
                :key="optionGroupCode"
                :model-value="selectedOptions[optionGroupCode]"
                :data-testid="`productOptions-${optionGroupCode}`"
                class="text-left lg:wfull"
                :class="{
                  'mt6 mb7': optionGroupCode === COLOR_OPTION_GROUP_CODE,
                }"
                @update:model-value="onOptionChange(optionGroupCode, $event)"
              >
                <div v-if="optionGroups?.[optionGroupCode]?.name" class="text-4 fw700">
                  <span :data-testid="`productOptionsName-${optionGroupCode}`">
                    {{ optionGroups[optionGroupCode].name }}
                  </span>
                  <span>: </span>
                  <span
                    :data-testid="`productOptionsSelectedOptionName-${optionGroupCode}`"
                    class="fw600"
                  >
                    {{ options[selectedOptions[optionGroupCode]]?.name }}
                  </span>
                </div>

                <HRadioGroupLabel
                  v-if="optionGroups?.[optionGroupCode]?.name"
                  :id="`option-group-${optionGroupCode}-label`"
                  class="sr-only"
                >
                  {{ t('form.choose_a_options.label', optionGroups[optionGroupCode].name) }}
                </HRadioGroupLabel>

                <div class="mt3 flex flex-wrap gap2" :data-testid="`productOptionsWrapper-${optionGroupCode}`">
                  <!--                    :disabled="!isOptionAvailable(optionGroupCode, optionCode)" -->
                  <HRadioGroupOption
                    v-for="(option, optionCode, index) in options"
                    :id="`option-group-${optionGroupCode}-option-${option.code}`"
                    :key="optionCode"
                    as="div"
                    :value="optionCode"
                    :name="option.name"
                    class="group relative inline-flex cursor-pointer items-center justify-center ws-nowrap border rounded text-sm text-context fw600 uppercase hover:bg-accent/50 n-active:bg-transparent n-active:ring-px n-active:ring-primary n-checked:bg-transparent focus:outline-none"
                    :class="[
                      isOptionAvailable(optionGroupCode, optionCode) ? 'text-primary  bg-muted' : 'bg-accent/50 text-slate-200',
                      isColorGroupWithImages(optionGroupCode) ? 'p0.75' : 'h8 px4 rd-full',
                    ]"
                    :data-testid="`productColorOptionsOption-${index}`"
                  >
                    <!--                    @click="handleOptionClick(optionGroupCode, optionCode)" -->
                    <HRadioGroupLabel
                      :id="`option-group-${optionGroupCode}-option-${option.code}-label`"
                      as="div"
                      :data-testid="isColorGroupWithImages(optionGroupCode) ? 'productImage' : 'productOption'"
                    >
                      <template v-if="isColorGroupWithImages(optionGroupCode) && option.featuredAsset?.preview">
                        <LazyNuxtImg
                          width="100"
                          height="100"
                          loading="lazy"
                          decoding="async"
                          sizes="16.666vw"
                          :src="option.featuredAsset.preview"
                          :alt="option.name"
                          class="h-16 w-16"
                          :class="isOptionAvailable(optionGroupCode, optionCode) ? '' : 'op25 grayscale'"
                          data-testid="productImageView"
                        />
                      </template>
                      <template v-else>
                        {{ option.name }}
                      </template>
                    </HRadioGroupLabel>

                    <span
                      v-if="isOptionAvailable(optionGroupCode, optionCode)"
                      class="pointer-events-none absolute border-px border-transparent rounded -inset-px n-active:border-px n-checked:border-primary"
                      :class="isColorGroupWithImages(optionGroupCode) ? '' : 'rd-full'"
                      aria-hidden="true"
                    />
                    <span
                      v-else
                      aria-hidden="true"
                      class="pointer-events-none absolute border-px border-input n-dashed -inset-px"
                      :class="isColorGroupWithImages(optionGroupCode) ? 'rd-md' : 'rd-full of-hidden'"
                    >
                      <svg
                        class="absolute inset-0 h-full w-full stroke-2 text-slate-200" viewBox="0 0 100 100"
                        preserveAspectRatio="none" stroke="currentColor"
                      >
                        <line x1="0" y1="100" x2="100" y2="0" vector-effect="non-scaling-stroke" />
                      </svg>
                    </span>
                  </HRadioGroupOption>
                </div>
              </HRadioGroup>

              <!-- Add to cart -->
              <section v-if="!isOutOfStock && !selectedVariantOutOfStock" class="mb5.25 mt6">
                <div class="mt4 box-border rd bg-amber-200/75 pb0.75 text-center md:wfull" data-testid="hint">
                  <span class="text-xs text-primary fw700 tracking-wide">
                    {{ t('product.only_a_few_pieces_left_in_stock') }}
                  </span>
                </div>
                <div class="flex flex-1 basis-full flex-col">
                  <div class="flex flex-1 basis-full flex-row items-end">
                    <UiButton
                      class="mt-2 h-auto w-full px-10 py-4 fw800 leading-none tracking-wide"
                      data-testid="addToCartButton"
                      :disabled="isOutOfStock"
                      @click="handleAddToCart"
                    >
                      {{ t('general.add_to_cart.label') }}
                    </UiButton>
                    <WishlistToggle
                      v-slot="{ alert, isItemWishlisted, isAnimating, handleWishlistClick }"
                      :product-id="product?.id" :product-variant-id="product?.variants[0]?.id"
                    >
                      <UiButton
                        variant="outline"
                        size="icon"
                        class="group/wishlist ml-2.5 p5.5 hover:bg-transparent"
                        :aria-pressed="isItemWishlisted"
                        data-testid="addToWishlist"
                        @click="handleWishlistClick"
                      >
                        <Icon
                          :name="isItemWishlisted ? 'ic:heart-fill' : 'ic:heart'"
                          class="block h6 w6 shrink-0 grow-0 basis-6 text-primary"
                          :class="
                            {
                              'group-hover/wishlist:animate-[2s_ease-in-out_0s_infinite_normal_none_running_heartbeat]': !isItemWishlisted && !isAnimating,
                              'animate-[500ms_ease-in-out_0s_1_normal_none_running_pulseOutward]': isAnimating && isItemWishlisted,
                              'animate-[500ms_ease-in-out_0s_1_normal_none_running_pulseInward]': isAnimating && !isItemWishlisted,
                            }
                          "
                          mode="svg"
                          :data-testid="`WishListIcon-${isItemWishlisted ? 'filled' : 'empty'}`"
                        />
                      </UiButton>
                    </WishlistToggle>
                  </div>
                </div>
              </section>
            </div>

            <UspList />

            <UiAccordion type="single" collapsible data-testid="productDetailsAccordions">
              <UiAccordionItem value="description" :data-testid="t('product.description')">
                <UiAccordionTrigger class="h16 hover:decoration-none">
                  <span class="flex items-center fw700">
                    {{ t('product.description') }}
                  </span>
                </UiAccordionTrigger>
                <UiAccordionContent>
                  <div class="prose" v-html="product?.description" />
                </UiAccordionContent>
              </UiAccordionItem>
              <UiAccordionItem v-if="product?.sizeChart" value="sizeAndFit" :data-testid="t('product.size_and_fit')">
                <UiAccordionTrigger class="h16 hover:decoration-none">
                  <span class="flex items-center fw700">
                    {{ t('product.size_and_fit') }}
                  </span>
                </UiAccordionTrigger>
                <UiAccordionContent>
                  <UiDialog v-model:open="chartModalOpen">
                    <UiDialogTrigger as-child>
                      <UiButton class="flex items-center fw700" variant="outline">
                        {{ t('product.size_chart.label') }}
                      </UiButton>
                    </UiDialogTrigger>
                    <UiDialogScrollContent class="md:max-w-[800px]">
                      <UiDialogHeader>
                        <UiDialogTitle class="sr-only">
                          {{ t('product.size_chart.label') }}
                        </UiDialogTitle>
                      </UiDialogHeader>

                      <ProductSizeChart
                        :size-chart="product.sizeChart"
                        size-type="mens"
                      />
                    </UiDialogScrollContent>
                  </UiDialog>
                </UiAccordionContent>
              </UiAccordionItem>
              <UiAccordionItem value="materialAndCare" :data-testid="t('product.material_and_care')">
                <UiAccordionTrigger class="h16 hover:decoration-none">
                  <span class="flex items-center fw700">
                    {{ t('product.material_and_care') }}
                  </span>
                </UiAccordionTrigger>
                <UiAccordionContent>
                  <div class="prose" v-html="product?.description" />
                </UiAccordionContent>
              </UiAccordionItem>
              <UiAccordionItem value="shippingAndReturns" :data-testid="t('product.shipping_and_returns')">
                <UiAccordionTrigger class="h16 hover:decoration-none">
                  <span class="flex items-center fw700">
                    {{ t('product.shipping_and_returns') }}
                  </span>
                </UiAccordionTrigger>
                <UiAccordionContent>
                  <div class="prose" v-html="product?.description" />
                </UiAccordionContent>
              </UiAccordionItem>
            </UiAccordion>

            <section class="my4 py1">
              <ProductLifestyleGallery :assets="lifestyleImages" />
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-if="id">
    <LazyProductReviews :product-id="id" />
  </section>

  <div v-if="id" class="flex flex-col">
    <aside data-testid="recommendedLanes">
      <section class="relative m-inline-auto max-w-[1920px] wfull pb6 p-inline-5 md:border-t-none md:pb12 !px0 lg:p-inline-7.5 xl:p-inline-15">
        <LazyProductListComplementaryLane :product-id="id" />
      </section>
      <section class="relative m-inline-auto max-w-[1920px] wfull pb6 p-inline-5 md:border-t-none md:pb12 !px0 lg:p-inline-7.5 xl:p-inline-15">
        <LazyProductListRecommendationLane :product-id="id" />
      </section>
      <section v-if="historyItems?.length">
        <LazyProductListHistoryLane :product-id="id" />
      </section>
    </aside>
  </div>

  <ClientOnly>
    <section>
      <div
        v-if="!productLoading"
        class="mx-auto max-w-3xl px2 pb8 pt2 lg:max-w-[88rem] lg:px6 md:max-w-4xl sm:px4 sm:pb12 sm:pt6"
      >
        <!--        <section v-if="collectionId" aria-labelledby="related-products-title"> -->
        <!--          <h2 id="related-products-title" class="text-base fw600" v-text="t('product.related_items.title')" /> -->
        <!--          <LazyProductsGrid -->
        <!--            :blocking="false" -->
        <!--            :search="{ collectionId }" -->
        <!--            @total="(v) => (total = v)" -->
        <!--            @loading="(loading) => (isLoading = loading)" -->
        <!--          /> -->
        <!--        </section> -->
      </div>
    </section>
  </ClientOnly>
</template>
